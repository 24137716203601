import React from "react";
import { Box, Button, Text, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import registerTalentApi from "../../../Api/Registration/registerTalentApi";
import checkForUserName from "../../Utility/checkForUserName";
import TalentRegistrationPersonalInfo from "./TalentRegistrationPersonalInfo";
import TalentRegistrationProfessionalInfo from "./TalentRegistrationProfessionalInfo";
import { useToast } from "@chakra-ui/react";
import { getUserInfoById } from "../../../Api/User/getUserById";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { currentUser } from "../../../Api/Auth/activateUser";
import uploadImage from "../../../Api/Lyrics/uploadImage";
import { State } from "country-state-city";
import { capitalizeFirstLetter } from "../../Utility/utils";


const TalentRegistration = () => {
  const [initial_load, setInitialLoad] = useState(true);
  const [loading, set_loading] = useState(false);
  const [editPage, setEditPage] = useState("");
  const [fname, set_fname] = useState("");
  const [username, set_username] = useState("");
  const [check_username_availability, set_check_username_availability] =
    useState(false);
  const [wa_number, set_wa_number] = useState("");
  const [city, set_city] = useState("");
  const [ustate, set_state] = useState("");
  const [description, set_description] = useState("");
  const [avatar, set_avatar] = useState("");
  const [avatar_object, set_avatar_object] = useState("");
  const [genre, set_genre] = useState([]);
  const [gear, set_gear] = useState([]);
  const [social_media, set_social_media] = useState([]);
  const [work, set_work] = useState([]);
  const [term, set_term] = useState([" "]);
  const [categories, set_categories] = useState([]);
  const [accept, set_accept] = useState(false);

  const { user } = useSelector((state) => ({ ...state }));

  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const dispatch = useDispatch();

  const id = user?.userId;

  useEffect(() => {
    const userId = id || location?.state?.id;
    if (!userId) return;

    set_loading(true);
    getUserInfoById(userId)
      .then((res) => {
        const data = res?.data;
        if (!data) {
          throw new Error('No data received from server');
        }

        set_fname(data.name || '');
        set_username(data.username || '');
        set_wa_number(data.mobile || '');
        set_city(data.city || '');
        set_state(data.state || '');
        set_description(data.description || '');
        set_avatar(data.avatar || '');
        
        // Set arrays with default empty values if null/undefined/empty
        set_genre(data.genres?.length ? data.genres : [{
          genre: "",
          subGenre: "",
        }]);
        
        set_gear(data.gearHighLights?.length ? data.gearHighLights : [{
          gear: "",
          gearHighLights: "",
        }]);
        
        set_categories(data.services?.length ? data.services : [{
          category: "",
          subCategory: "",
          serviceStargingPrice: "",
        }]);
        
        // Convert social media object to array format
        let socialMediaArray = [];
        if (data.socialMedia) {
          // Handle both object and array formats
          if (Array.isArray(data.socialMedia)) {
            socialMediaArray = data.socialMedia;
          } else {
            // Convert object format to array format
            socialMediaArray = Object.entries(data.socialMedia).map(([platform, link]) => ({
              plat: platform,
              link: link
            }));
          }
        }
        set_social_media(socialMediaArray.length ? socialMediaArray : [{
          plat: "",
          link: "",
        }]);
        
        set_term(data.terms?.length ? data.terms : ['']);
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: 'Failed to load user information. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      })
      .finally(() => {
        setInitialLoad(false);
        set_loading(false);
      });
  }, [id, location, toast]);

  const checkFields = () => {
    if (!fname) return { status: false, error: "Name is required." };
    if (!username) return { status: false, error: "Username is required." };
    if (!wa_number)
      return { status: false, error: "Whatsapp number is required." };
    if (!city) return { status: false, error: "City is required." };
    if (!ustate) return { status: false, error: "State is required." };
    if (!description)
      return { status: false, error: "Description is required" };
    else {
      if (description.length < 120)
        return {
          status: false,
          error: "Description require at least 120 words.",
        };
    }

    // check categories
    for (let i = 0; i < categories.length; i++) {
      if (!categories[i].category)
        return {
          status: false,
          error: "Please select the preferred category.",
        };
      if (categories[i].serviceStargingPrice.toString() === "")
        return {
          status: false,
          error: "Please select the service starting price in category.",
        };
      if (categories[i].subCategory === "")
        return {
          status: false,
          error: "Please select the sub-category in category.",
        };
    }

    // check genre
    for (let i = 0; i < genre.length; i++) {
      if (genre[i].genre === "")
        return {
          status: false,
          error: "Genre is required.",
        };
      if (genre[i].subGenre === "")
        return {
          status: false,
          error: "Please select the sub-genre genre.",
        };
    }

    // check gear
    for (let i = 0; i < gear.length; i++) {
      if (gear[i].gear === "")
        return {
          status: false,
          error: "Gear is required.",
        };
      if (gear[i].gearHighLights === "")
        return {
          status: false,
          error: "Gear highlights is requird in gears.",
        };
    }

    // check social media
    // if (social_media.length > 0) {
    //   for (let i = 0; i < social_media.length; i++) {
    //     if (social_media[i].plat === "")
    //       return {
    //         status: false,
    //         error: "Social Media is requird",
    //       };
    //     if (social_media[i].link === "") {
    //       return {
    //         status: false,
    //         error: "Social Media is required.",
    //       };
    //     } else {
    //       const val = isValidURL(social_media[i].link);

    //       if (!val.status) {
    //         return val;
    //       }
    //     }
    //   }
    // }

    // check terms and services
    for (let i = 0; i < term.length; i++) {
      if (term[i] === "") {
        return { status: false, error: "Terms and Services is required." };
      }
    }

    // check agree tick
    if (!accept)
      return {
        status: false,
        error:
          "To proceed further you need to accept our terms and conditions.",
      };

    return {
      status: true,
      error: "",
    };
  };

  // functions to handle submissions
  const handleSubmit = async () => {
    try {
      set_loading(true);
      
      // Validate fields first
      const fieldsCheck = checkFields();
      if (!fieldsCheck.status) {
        throw new Error(fieldsCheck.error);
      }

      // Check username availability
      const usernameCheck = await checkForUserName(username);
      if (usernameCheck === "notAvailable") {
        set_check_username_availability(true);
        throw new Error("Username is not available");
      }

      // Get state name from code
      const stateData = State.getStatesOfCountry("IN").find(val => val.isoCode === ustate);
      if (!stateData) {
        throw new Error("Invalid state selected");
      }
      const t_state = stateData.name;

      // Upload avatar
      const avatarUrl = await uploadImage(avatar_object);
      
      // Calculate starting price
      const startingPrice = Math.min(...categories.map(cat => 
        Number(cat.serviceStargingPrice) || Number.MAX_VALUE
      ));

      // Prepare social media data
      const socialMediaObject = social_media.reduce((acc, {plat, link}) => {
        if (plat && link) {
          acc[plat] = link;
        }
        return acc;
      }, {});

      // Register talent
      const registrationData = {
        id: user?.userId || user._id,
        fname,
        username,
        wa_number,
        city,
        t_state,
        description,
        categories,
        genre,
        gear,
        social_media: socialMediaObject,
        work,
        term,
        avatar: avatarUrl,
        startingPrice,
      };

      const registerResponse = await registerTalentApi(registrationData);
      
      // Store token
      localStorage.setItem("token", location.state.token);
      
      // Get updated user data
      const userData = await currentUser(location.state.token);
      
      // Update Redux store
      dispatch({
        type: "LOGGED_IN_USER",
        payload: {
          userId: userData?.data?._id,
          name: userData?.data?.name,
          email: userData?.data?.email,
          token: location.state.token,
          isMusician: userData?.data?.isMusician,
          isProfileCompleted: userData?.data?.isProfileCompleted,
          qr: userData?.data?.profileUrl,
          avatar: userData?.data?.avatar,
          role: userData?.data?.role,
          genres: userData?.data?.genres,
          services: userData?.data?.services,
          gearHighLights: userData?.data?.gearHighLights,
          review: userData?.data?.review,
          terms: userData?.data?.terms,
          postedJobs: userData?.data?.postedJobs,
          startingPrice: userData?.data?.startingPrice,
          jobsCompleted: userData?.data?.jobsCompleted,
          totalEarn: userData?.data?.totalEarn,
          repeatedBuyer: userData?.data?.repeatedBuyer,
          socialMedia: userData?.data?.socialMedia,
          city: userData?.data?.city,
          description: userData?.data?.description,
          mobile: userData?.data?.mobile,
          userName: userData?.data?.userName,
        },
      });

      // Set success state
      sessionStorage.setItem("profileStatus", "success");
      sessionStorage.setItem("profileType", "client");
      
      toast({
        title: "Success",
        description: "Registered Successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });

      navigate("/dashboard");

    } catch (error) {
      toast({
        title: "Error",
        description: capitalizeFirstLetter(error.message),
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } finally {
      set_loading(false);
      set_check_username_availability(false);
    }
  };

  return (
    <>
      {!initial_load ? (
        <Box pt="8.5vh" overflowX={"hidden"}>
          {/* <NavBar/> */}
          <Box px={{ base: "7vw", lg: "13.54vw" }} pt="6.01vh" pb="100px">
            <Text
              display={"block"}
              fontSize={{ base: "1.5rem", lg: "2.29vw" }}
              fontFamily={"Gilroy-Bold"}
            >
              Talent Registration
            </Text>
            {/* personal info */}
            <TalentRegistrationPersonalInfo
              data={{
                fname,
                set_fname,
                username,
                set_username,
                wa_number,
                set_wa_number,
                city,
                set_city,
                ustate,
                set_state,
                description,
                set_description,
                check_username_availability,
                avatar,
                set_avatar,
                set_avatar_object,
              }}
            />

            {/* professional info */}
            <TalentRegistrationProfessionalInfo
              data={{
                categories,
                set_categories,
                gear,
                set_gear,
                genre,
                set_genre,
                social_media,
                set_social_media,
                work,
                set_work,
                term,
                set_term,
                accept,
                set_accept,
              }}
            />

            <Button
              mt="3.70vh"
              w="36.04vw"
              bg="rgba(246, 84, 14, 1)"
              color="white"
              h={"6.48vh"}
              borderRadius={{ base: "15px", lg: "1.04vw" }}
              fontFamily={"Gilroy-SemiBold"}
              fontSize={{ base: "1rem", md: "1.5rem", lg: ".833vw" }}
              _hover={{ background: "rgba(246, 84, 14, 1)" }}
              onClick={handleSubmit}
              isLoading={loading}
            >
              {editPage === true ? "Update Profile" : "Create Profile"}
            </Button>
          </Box>
          {/* <Footer /> */}
        </Box>
      ) : (
        <Box
          width={"100vw"}
          height={"100vh"}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <Spinner color="orange" size={"lg"} />
        </Box>
      )}
    </>
  );
};

export default TalentRegistration;
