import { Box, Button, Icon, Text } from "@chakra-ui/react";
import { AiOutlineRight } from "react-icons/ai";
import "./talents.css";
import GoToTop from "../../../Utility/goToTop";
//icons
import { ReactComponent as ArrowLeft } from "../../../../Assets/Icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../../Assets/Icons/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCategoryContext } from "../../../Context/CategoryContext";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@splidejs/react-splide/css";

const talent_data = [
  { title: "Vocalist", link: { type: "category", value: "VOCALISTS" } },
  {
    title: "Music Producers",
    link: { type: "category", value: "MUSIC PRODUCERS  & ENGINEERS" },
  },
  {
    title: "Song Writers & Composers",
    link: { type: "category", value: "SONGWRITERS & COMPOSERS " },
  },
  { title: "DJ", link: { type: "category", value: "DJs" } },
  { title: "Bassist", link: { type: "", value: "" } },
  { title: "Drummer", link: { type: "category", value: "Drums" } },
  { title: "Flutist", link: { type: "category", value: "Flutes" } },
  { title: "Guitarist", link: { type: "category", value: "Acoustic Guitar" } },
  { title: "Pianist", link: { type: "category", value: "Piano" } },
  { title: "Sound Engineer", link: { type: "", value: "" } },
  { title: "Tabla Player", link: { type: "category", value: "Tabla" } },
  { title: "Violinist", link: { type: "category", value: "Violon" } },
];

const Talents = () => {
  const navigate = useNavigate();
  const { categoryF, setCategoryF } = useCategoryContext();
  const width = window.innerWidth;

  return (
    <Box
      bg="#fff"
      py="50px"
      px={{ base: "7vw", lg: "13.54vw" }}
      h="fit-content"
      display={"flex"}
      flexDir="column"
      justifyContent={"center"}
      gap={"1vh"}
      position="relative"
      id="talent_container"
    >
      <Box
        display={"flex"}
        flexDir={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "flex-start", lg: "center" }}
        gap={{ base: "1rem" }}
        marginBottom={"10px"}
      >
        <Box w={{ base: "95%", lg: "50%", background: "orange" }}>
          <Text
            className="talent"
            fontSize={{ base: "2rem !important", lg: "2.29vw" }}
            fontFamily={"Gilroy-Bold"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={{ lg: "60%" }}
          >
            <Text>Talents</Text>
            <Text
              fontSize={{ base: "1.2rem", lg: "1.04vw" }}
              fontFamily={"Gilroy-Medium"}
            >
              A whole world of musical talent at your service - browse through
              our list of musicians to find the one perfect for your job
            </Text>
          </Text>
          <Button
            display={{ base: "block", lg: "none" }}
            position="relative"
            w={{ base: "200px", lg: "12.55vw" }}
            h={{ base: "5rem", lg: "6.9vh" }}
            fontSize={{ base: "1.2rem", lg: ".9vw" }}
            fontFamily={"Gilroy-SemiBold"}
            fontWeight="normal"
            borderRadius={{ base: "1.5rem", lg: "1.04vw" }}
            onClick={() => navigate("/talents")}
            marginTop={{ base: "1rem" }}
          >
            Sell all categories <Icon as={AiOutlineRight} ml="5px" />
          </Button>
        </Box>
        <Box>
          <Button
            display={{ base: "none", lg: "block" }}
            position="relative"
            w={{ base: "15rem", lg: "12.55vw" }}
            h={{ base: "5rem", lg: "6.9vh" }}
            fontSize={{ base: "1.3rem", lg: ".9vw" }}
            fontFamily={"Gilroy-SemiBold"}
            fontWeight="normal"
            borderRadius={{ base: "2rem", lg: "1.04vw" }}
            onClick={() => navigate("/talents")}
          >
            Sell all categories <Icon as={AiOutlineRight} ml="5px" />
          </Button>
        </Box>
      </Box>

      {/* Slide Section */}
      <Splide
        onScroll={(item) => {}}
        options={{
          arrows: true,
          perPage: width < 500 ? 1 : 4,
          rewind: false,
          width: "100%",
          gap: width < 500 ? "3rem" : "1rem",
          perMove: 1,
          type: "loop",
        }}
      >
        {talent_data.map((val, index) => {
          return (
            <SplideSlide key={index} style={{ width: "100%" }}>
              <Box
                h="25.92vh"
                bgSize="cover"
                borderRadius="28px"
                overflow="hidden"
                className={`talents-card-${index + 1}`}
                cursor={"pointer"}
                onClick={() => {
                  setCategoryF("Vocalist");
                  navigate("/talents", {
                    state: val.link,
                  });
                }}
              >
                <Box
                  h="100%"
                  w="100%"
                  bg={
                    "linear-gradient(180deg, rgba(43, 43, 43, 0) 0%, rgba(43, 43, 43, 0.8) 100%)"
                  }
                  position="relative"
                >
                  <Text
                    position={"absolute"}
                    bottom="2.26vh"
                    left={"1.45vw"}
                    color="#fff"
                    fontSize={{ base: "2rem", lg: "1.45vw" }}
                    fontFamily={"Gilroy-Bold"}
                  >
                    {val.title}
                  </Text>
                </Box>
                <GoToTop />
              </Box>
            </SplideSlide>
          );
        })}
      </Splide>
    </Box>
  );
};

export default Talents;
