import { Box, Select, Text } from "@chakra-ui/react";
import { useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../../Assets/Icons/Delete.svg";
import { GenreData } from "../../../../Data/GenreData";
import styles from "../../../../Styles/genre.module.css";

const Genre = ({ showDelete, changeState, currentIndex, fullState }) => {
  const [genre, setGenre] = useState("National/Indian");

  const handleDelete = () => {
    changeState((prev) => {
      prev.splice(currentIndex, 1);
      return [...prev];
    });
  };

  const updating_genre = (e) => {
    setGenre(e.target.value);
    changeState((prev) => {
      prev[currentIndex].genre = e.target.value;
      return [...prev];
    });
  };

  const updating_sub_genre = (e) => {
    changeState((prev) => {
      prev[currentIndex].subGenre = e.target.value;
      return [...prev];
    });
  };

  return (
    <Box
      display={"grid"}
      gridTemplateColumns={"1fr 1fr"}
      gap=".833vw"
      mt="1.111vh"
      position={"relative"}
      paddingRight={{ base: showDelete ? "10px" : "0" }}
    >
      <Box flexGrow={1}>
        <Text
          fontFamily={"Gilroy-SemiBold"}
          fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
        >
          Genre*
        </Text>
        <Select
          borderRadius={"15px"}
          placeholder="Select"
          value={fullState[currentIndex].genre}
          onChange={updating_genre}
          h="6.48vh"
        >
          {Object.keys(GenreData).map((item) => (
            <option value={item}>{item}</option>
          ))}
        </Select>
      </Box>
      <Box flexGrow={1}>
        <Text
          fontFamily={"Gilroy-SemiBold"}
          fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
        >
          Subgenre*
        </Text>
        <Select
          borderRadius={"15px"}
          placeholder="Select"
          value={fullState[currentIndex].subGenre}
          onChange={updating_sub_genre}
          h="6.48vh"
        >
          {GenreData[genre].map((item) => (
            <option value={item}>{item}</option>
          ))}
        </Select>
      </Box>
      {showDelete ? (
        <DeleteIcon className={styles.icon} onClick={handleDelete} />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Genre;
