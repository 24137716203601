import {
  Box,
  Button,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  Stack,
  HStack,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import logo from "../../../Assets/Images/Logo/logo.svg";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import jsonp from "jsonp";
import { useState } from "react";
import styles from "./footer.module.css";
import { useSelector } from "react-redux";
import { ReactComponent as Sms } from "../../../Assets/Icons/sms.svg";

const action_url =
  "https://udukku.us14.list-manage.com/subscribe/post-json?u=e18ebd0a4d8f02856ff89be0a&amp;id=28239d7933&amp;f_id=008799e0f0";

const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { user } = useSelector((state) => ({ ...state }));

  const onSubmitHandler = () => {
    setLoading(true);
    jsonp(`${action_url}&EMAIL=${email}`, { param: "c" }, (err, data) => {
      setLoading(false);

      if (data.result === "success") {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }

      if (err) {
        toast({
          title: "Error",
          description: "Something went wrong. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    });
  };

  return (
    <Box
      h={{ base: "fit-content" }}
      w="100%"
      maxW="100vw"
      overflow={"hidden"}
      className="hide-scroll-bar"
      bg="#082032"
      display={"grid"}
      gridTemplateColumns={{ base: "1fr", md: "1fr 1fr", lg: ".7fr 1.3fr .7fr" }}
      gap={{ base: "2rem", md: "3rem", lg: "4rem" }}
      py={{ base: "3rem", md: "4rem", lg: "6.77vh" }}
      color="#fff"
      px={{ base: "1.5rem", md: "3rem", lg: "13.54vw" }}
    >
      <Box display={"flex"} flexDir="column" gap="1.5rem">
        <Box>
          <Image
            src={logo}
            h={{ base: "60px", md: "70px", lg: "80px" }}
            width={"auto"}
            maxW="150px"
          />
        </Box>
        
        <Box>
          <Text
            mb="1rem"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "14px", md: "15px", lg: "16px" }}
            lineHeight="1.6"
          >
            A one-of-its-kind destination for musicians to harness their
            love of music and connect with each other.
          </Text>
          
          <Stack spacing="1rem">
            <HStack align="flex-start" spacing="0.75rem">
              <Icon
                as={HiLocationMarker}
                fontSize="20px"
                color="rgba(255,255,255,0.9)"
              />
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "14px", md: "14px", lg: "15px" }}
                color="rgba(255,255,255,0.9)"
              >
                M-23 Income Tax Colony, Tonk Road, Durgapura, Jaipur - 302018
              </Text>
            </HStack>
            
            <HStack spacing="0.75rem">
              <Sms height="20px" width="20px" />
              <Text
                as="a"
                href="mailto:info@udukku.com"
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "14px", md: "14px", lg: "15px" }}
                color="rgba(255,255,255,0.9)"
                className={styles.footerLink}
              >
                info@udukku.com
              </Text>
            </HStack>
          </Stack>
        </Box>

        <HStack
          display={{ base: "flex", lg: "none" }}
          spacing="1rem"
          mt="1rem"
        >
          <Box
            as="a"
            href="https://www.facebook.com/udukkumusic/"
            target="_blank"
            w={{ base: "2.4rem", lg: "2vw" }}
            h={{ base: "2.4rem", lg: "2vw" }}
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            className={`${styles.socialIcon} hide-scroll-bar facebook`}
            border="1px solid rgba(255,255,255,.2)"
            borderRadius={"5px"}
          >
            <Icon
              as={FaFacebookF}
              color="rgb(255,255,255)"
              fontSize={{ base: "1rem", lg: ".833vw" }}
            />
          </Box>
          <Box
            as="a"
            w={{ base: "2.4rem", lg: "2vw" }}
            h={{ base: "2.4rem", lg: "2vw" }}
            href="https://www.instagram.com/udukkumusic/"
            target="_blank"
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            className={`${styles.socialIcon} hide-scroll-bar instagram`}
            border="1px solid rgba(255,255,255,.2)"
            borderRadius={"5px"}
          >
            <Icon
              as={FaInstagram}
              color="rgb(255,255,255)"
              fontSize={{ base: "1rem", lg: ".833vw" }}
            />
          </Box>

          <Box
            as="a"
            href="https://twitter.com/udukkumusic"
            target="_blank"
            w={{ base: "2.4rem", lg: "2vw" }}
            h={{ base: "2.4rem", lg: "2vw" }}
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            className={`${styles.socialIcon} hide-scroll-bar twitter`}
            border="1px solid rgba(255,255,255,.2)"
            borderRadius={"5px"}
          >
            <Icon
              as={FaTwitter}
              color="rgb(255,255,255)"
              fontSize={{ base: "1rem", lg: ".833vw" }}
            />
          </Box>
        </HStack>
      </Box>

      <SimpleGrid
        columns={{ base: 1, sm: 2 }}
        spacing={{ base: "2rem", sm: "3rem" }}
      >
        {user?.isMusician !== "Musician" && (
          <Box>
            <Text
              fontSize={{ base: "18px", lg: "22px" }}
              fontFamily={"Gilroy-Bold"}
              mb="1rem"
            >
              Categories
            </Text>
            <VStack align="flex-start" spacing="0.75rem">
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "category",
                      value: "VOCALISTS",
                    },
                  });
                }}
                cursor={"pointer"}
                className={styles.footerLink}
              >
                Vocalists
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "category",
                      value: "MUSIC PRODUCERS  & ENGINEERS",
                    },
                  });
                }}
                cursor={"pointer"}
                className={styles.footerLink}
              >
                Music Producer & Engineers
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "genre",
                      value: "National/Indian",
                    },
                  });
                }}
                cursor={"pointer"}
                className={styles.footerLink}
              >
                Indian Instruments
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: { type: "genre", value: "International" },
                  });
                }}
                cursor={"pointer"}
                className={styles.footerLink}
              >
                International Instruments
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "category",
                      value: "SONGWRITERS & COMPOSERS ",
                    },
                  });
                }}
                cursor={"pointer"}
                className={styles.footerLink}
              >
                Songwriters & Composers
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "category",
                      value: "DJs",
                    },
                  });
                }}
                cursor={"pointer"}
                className={styles.footerLink}
              >
                DJs
              </Text>
            </VStack>
          </Box>
        )}

        <Box>
          <Text
            fontSize={{ base: "18px", lg: "22px" }}
            fontFamily={"Gilroy-Bold"}
            mb="1rem"
          >
            Support
          </Text>
          <VStack align="flex-start" spacing="0.75rem">
            <Text
              fontFamily={"Gilroy-Medium"}
              fontSize={{ base: "12px", lg: ".833vw" }}
              cursor={"pointer"}
              onClick={() => {
                navigate("/privacy-policy");
              }}
              className={styles.footerLink}
            >
              Privacy Policy
            </Text>

            <Text
              fontFamily={"Gilroy-Medium"}
              fontSize={{ base: "12px", lg: ".833vw" }}
              cursor={"pointer"}
              onClick={() => {
                navigate("/cancellation-and-refund");
              }}
              className={styles.footerLink}
            >
              Cancellation & Refund Policy
            </Text>
            <Text
              fontFamily={"Gilroy-Medium"}
              fontSize={{ base: "12px", lg: ".833vw" }}
              cursor={"pointer"}
              onClick={() => {
                navigate("/terms-and-conditions");
              }}
              className={styles.footerLink}
            >
              Terms & Conditions
            </Text>
            <Text
              fontFamily={"Gilroy-Medium"}
              fontSize={{ base: "12px", lg: ".833vw" }}
              cursor={"pointer"}
              onClick={() => {
                navigate("/about-us");
              }}
              className={styles.footerLink}
            >
              About Us
            </Text>
            <Text
              fontFamily={"Gilroy-Medium"}
              fontSize={{ base: "12px", lg: ".833vw" }}
              cursor={"pointer"}
              onClick={() => {
                navigate("/contact-us");
              }}
              className={styles.footerLink}
            >
              Contact Us
            </Text>
          </VStack>
        </Box>
      </SimpleGrid>

      <Box>
        <Text
          fontSize={{ base: "18px", lg: "22px" }}
          fontFamily={"Gilroy-Bold"}
          mb="1rem"
          textAlign={{ base: "left", lg: "center" }}
        >
          Subscribe to hear news
        </Text>
        
        <Box
          bg="white"
          borderRadius="1rem"
          p="5px"
          display="flex"
          flexDir={{ base: "column", sm: "row" }}
          gap="5px"
        >
          <Input
            className={styles.input_}
            placeholder="Email Address"
            size="lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            minW={{ base: "100%", sm: "100px" }}
            h="40px"
            bg="rgba(246, 84, 14, 1)"
            color="white"
            borderRadius="1rem"
            _hover={{
              bg: "#0E87F6",
              transform: "translateY(-2px)",
            }}
            transition="all 0.3s ease"
            onClick={onSubmitHandler}
            isLoading={loading}
          >
            Send
          </Button>
        </Box>

        <HStack
          display={{ base: "none", lg: "flex" }}
          justify="center"
          spacing="1rem"
          mt="2rem"
        >
          <Box
            as="a"
            href="https://www.facebook.com/udukkumusic/"
            target="_blank"
            w={{ base: "2.4rem", lg: "2vw" }}
            h={{ base: "2.4rem", lg: "2vw" }}
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            className={`${styles.socialIcon} hide-scroll-bar facebook`}
            border="1px solid rgba(255,255,255,.2)"
            borderRadius={"5px"}
          >
            <Icon
              as={FaFacebookF}
              color="rgb(255,255,255)"
              fontSize={{ base: "2rem", lg: ".833vw" }}
            />
          </Box>
          <Box
            as="a"
            w={{ base: "2.4rem", lg: "2vw" }}
            h={{ base: "2.4rem", lg: "2vw" }}
            href="https://www.instagram.com/udukkumusic/"
            target="_blank"
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            className={`${styles.socialIcon} hide-scroll-bar instagram`}
            border="1px solid rgba(255,255,255,.2)"
            borderRadius={"5px"}
          >
            <Icon
              as={FaInstagram}
              color="rgb(255,255,255)"
              fontSize={{ base: "2rem", lg: ".833vw" }}
            />
          </Box>

          <Box
            as="a"
            href="https://twitter.com/udukkumusic"
            target="_blank"
            w={{ base: "2.4rem", lg: "2vw" }}
            h={{ base: "2.4rem", lg: "2vw" }}
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            className={`${styles.socialIcon} hide-scroll-bar twitter`}
            border="1px solid rgba(255,255,255,.2)"
            borderRadius={"5px"}
          >
            <Icon
              as={FaTwitter}
              color="rgb(255,255,255)"
              fontSize={{ base: "2rem", lg: ".833vw" }}
            />
          </Box>
        </HStack>
      </Box>

      <Text
        display={{ base: "block", md: "none" }}
        fontFamily={"Gilroy-Medium"}
        fontSize="14px"
        color="rgba(255,255,255,0.7)"
      >
        ©udukku {new Date().getFullYear()}
      </Text>
    </Box>
  );
};

export default Footer;
