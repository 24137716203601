import { Box, Button, Image, Link, Text } from "@chakra-ui/react";
import { useEffect, useState, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../../Components/Footer/Footer";
import NavBar from "../../Components/NavBar/NavBar";
// Icons
import { ReactComponent as FbIcon } from "../../../Assets/Icons/fb.svg";
import { ReactComponent as Youtube } from "../../../Assets/Icons/socialMedia/youtube.svg";
import { ReactComponent as InstaIcon } from "../../../Assets/Icons/insta.svg";
import { ReactComponent as SoundCouldIcon } from "../../../Assets/Icons/Soundcloud_1_.svg";
import { ReactComponent as Star } from "../../../Assets/Icons/star.svg";
import { ReactComponent as MusicIcon } from "../../../Assets/Icons/VectorGen.svg";
import { ReactComponent as Monitor } from "../../../Assets/Icons/monitor.svg";
import { ReactComponent as Headphone } from "../../../Assets/Icons/headphone.svg";
import { ReactComponent as Microphone } from "../../../Assets/Icons/microphone-2.svg";
import { ReactComponent as Driver } from "../../../Assets/Icons/driver.svg";
import { ReactComponent as PlayIcon } from "../../../Assets/Icons/play.svg";
import { ReactComponent as Sms } from "../../../Assets/Icons/sms.svg";
import { ReactComponent as Category } from "../../../Assets/Icons/category.svg";
import ReviewCard from "../../Components/ReviewCard/ReviewCard";
import { Country, State, City } from "country-state-city";
import styles from "../../../Styles/profile.module.css";

// dummy data
import { AccessAuthContext } from "../../Context/AuthContext";
import { useSelector } from "react-redux";

const Profile = () => {
  const location = useLocation();

  const username = useParams().username;

  const { userId } = AccessAuthContext();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [userData, setUserData] = useState({});
  const { user } = useSelector((state) => ({ ...state }));

  const commonCall = async () => {
    setLoading(true);
    try {
      // If logged in user is viewing their own profile
      if (user?.userId && user?.userName === username) {
        setUserData({ ...user });
        return setLoading(false);
      }

      // Fetch user data for other profiles
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/get-user-by-username`,
        { userName: username }
      );

      if (!res.data?.user?.[0]) {
        console.error("No user data found");
        return setUserData(null);
      }

      const userData = res.data.user[0];
      
      // Transform socialMedia object with null checks
      const socialMediaArray = userData.socialMedia 
        ? Object.entries(userData.socialMedia)
            .filter(([_, link]) => link) // Filter out empty links
            .map(([plat, link]) => ({
              plat,
              link
            })) 
        : [];

      setUserData({
        ...userData,
        socialMedia: socialMediaArray,
        startingPrice: Array.isArray(userData.startingPrice) 
          ? userData.startingPrice[0] 
          : userData.startingPrice || 0, // Provide default value
        genres: userData.genres || [],
        terms: userData.terms || [],
        gearHighLights: userData.gearHighLights || [],
        services: userData.services || []
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUserData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    commonCall();
  }, [username]);

  if (!loading && !userData) {
    return (
      <Box>
        <NavBar />
        <Box px={{ base: "7vw", lg: "13.54vw" }} pt={{ base: "7rem", lg: "12rem" }}>
          <Text>User not found</Text>
        </Box>
        <Footer />
      </Box>
    );
  }

  return (
    <>
      <Box overflowX={"hidden"}>
        <NavBar />

        {loading && (
          <div>
            <h1> Loading</h1>
          </div>
        )}

        {!loading && (
          <Box
            px={{ base: "7vw", lg: "13.54vw" }}
            pt={{ base: "7rem", lg: "12rem" }}
            minH="calc(100vh - 7.40vh)"
          >
            <Text fontFamily={"Gilroy-Bold"} fontSize="3rem" pb="1rem">
              MY PROFILE
            </Text>
            <Box
              display={{ md: "grid", sm: "block" }}
              gridTemplateColumns={"1fr 1fr"}
              gap="1.85vw"
              mb={"5%"}
            >
              <Box w={{ md: "36.04vw", sm: "100%" }} h="fit-content">
                <Box display={"flex"} flexDir="column" gap="1.04vw">
                  {/* Avatar Box */}
                  <Box
                    position={"relative"}
                    width={{ base: "100%", lg: "90%" }}
                    style={{ aspectRatio: "1/1" }}
                    bgImage={`url('${userData?.avatar}')`}
                    borderRadius="20px"
                    backgroundPosition={"center"}
                    backgroundSize="cover"
                    padding="15px"
                    display={"flex"}
                    flexDir="column"
                    alignItems={"flex-end"}
                    justifyContent="flex-end"
                  >
                    {user?.isMusician === "Musician" &&
                    user?.userName === username ? (
                      <Button
                        mt="3.70vh"
                        h="6.48vh"
                        w="100%"
                        borderRadius={"10px"}
                        bg="#F6540E"
                        _hover={{ background: "#F6540E" }}
                        onClick={() =>
                          navigate("/edit-profile", {
                            state: {
                              data: user,
                              prevPath: location.pathname,
                            },
                          })
                        }
                      >
                        <Sms
                          className="genre-category-icons2"
                          style={{
                            width: "1.25vw",
                            height: "1.25vw",
                          }}
                        />
                        <Text
                          className="lyrics-heading-2"
                          ml=".36vw"
                          color="white"
                          fontFamily={"Gilroy-SemiBold"}
                          fontSize=".833vw"
                        >
                          Edit Profile
                        </Text>
                      </Button>
                    ) : user?.isMusician === "Recruter" &&
                      user?.userName === userData.userName ? (
                      <Button
                        mt="3.70vh"
                        h="6.48vh"
                        w="100%"
                        borderRadius={"1.04vw"}
                        bg="#F6540E"
                        _hover={{ background: "#F6540E" }}
                        onClick={() =>
                          navigate("/creator-edit-profile", {
                            state: {
                              data: user,
                              prevPath: location.pathname,
                            },
                          })
                        }
                      >
                        <Sms
                          style={{
                            width: "1.25vw",
                            height: "1.25vw",
                          }}
                        />
                        <Text
                          ml=".36vw"
                          color="white"
                          fontFamily={"Gilroy-SemiBold"}
                          fontSize=".833vw"
                        >
                          Edit Profile
                        </Text>
                      </Button>
                    ) : (
                      <Button
                        mt="3.70vh"
                        h="6.48vh"
                        w="100%"
                        borderRadius={{ base: "1rem", lg: "1.04vw" }}
                        bg="#F6540E"
                        _hover={{ background: "#F6540E" }}
                        onClick={() =>
                          navigate("/messages", {
                            state: {
                              data: user,
                              prevPath: location.pathname,
                            },
                          })
                        }
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Sms className={styles.icon} />
                        <Text
                          ml=".36vw"
                          color="white"
                          fontFamily={"Gilroy-SemiBold"}
                          fontSize={{ base: "1rem", lg: ".833vw" }}
                          display={"flex"}
                          alignItems={"center"}
                          paddingTop={{ base: "2px" }}
                          marginLeft={{ base: ".5rem" }}
                        >
                          Contact
                        </Text>
                      </Button>
                    )}
                  </Box>

                  <Box
                    display={{ md: "flex", sm: "block" }}
                    w={{ lg: "90%", sm: "100%" }}
                    flexDir="row"
                    gap="10px"
                  >
                    {/* User name, city and state */}
                    <Box>
                      <Text
                        className="lyrics-heading-1"
                        fontFamily={"Gilroy-Bold"}
                        fontSize="1.66vw"
                      >
                        {userData?.name}
                      </Text>
                      <Text
                        className="lyrics-heading-2"
                        fontFamily={"Gilroy-SemiBold"}
                        fontSize="1.04vw"
                        color="rgba(43, 43, 43, .5)"
                      >
                        {userData?.city}, {userData?.state}
                      </Text>
                    </Box>

                    {/* User Social Media Icons */}
                    <Box
                      display={"flex"}
                      flexDir="row"
                      justifyContent={{ md: "center", sm: "left" }}
                      alignItems={"flex-start"}
                      gap="1.11vh"
                      ml="auto"
                      mt="2%"
                    >
                      {Array.isArray(userData?.socialMedia) && userData.socialMedia.map((val, index) => (
                        <Box
                          className={styles.social_media_icon}
                          as="a"
                          href={val?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                        >
                          {val?.plat === "instagram" && <InstaIcon />}
                          {val?.plat === "youtube" && <Youtube />}
                          {val?.plat === "soundcloud" && <SoundCouldIcon />}
                          {val?.plat === "facebook" && <FbIcon />}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>

                <Box className="d-hide" w="100%">
                  <Box
                    w="100%"
                    mt="2.22vh"
                    px="1.45vw"
                    pt="2.59vh"
                    pb="1.48vh"
                    borderRadius="1.66vw"
                  >
                    <Box display={"flex"} justifyContent="space-between">
                      <Text
                        className="lyrics-heading-2"
                        fontFamily={"Gilroy-Bold"}
                        fontSize="1.45vw"
                      >
                        Starting Price:
                      </Text>
                      <Text
                        className="lyrics-heading-2"
                        fontFamily={"Gilroy-Bold"}
                        fontSize="1.45vw"
                      >
                        ₹ {userData?.startingPrice}
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      display={"block"}
                      gridTemplateColumns="1fr 1fr"
                      columnGap={".833vw"}
                      rowGap="1.48vh"
                      mt="2.22vh"
                    ></Box>
                  </Box>
                </Box>

                <Box w="100%" mt="2.96vh">
                  {/* heading */}
                  <Text
                    className="lyrics-heading-1"
                    fontFamily="Gilroy-Bold"
                    fontSize={"1.45vw"}
                  >
                    {userData.tag}
                  </Text>

                  {/* Tags */}
                  <Box
                    display={{ base: "flex", md: "flex", sm: "block" }}
                    pt="10px"
                    gap={{ base: ".5rem", lg: ".416vw" }}
                    flexWrap={"wrap"}
                  >
                    {userData?.genres?.map((g, index) => (
                      <>
                        <Box
                          key={index}
                          h="4.07vh"
                          display={"inline-flex"}
                          alignItems="center"
                          gap={".55vw"}
                          bg="rgba(247, 215, 22, .1)"
                          py="1.20vh"
                          pl=".75vw"
                          pr=".62vw"
                          borderRadius={".833vw"}
                        >
                          <MusicIcon
                            className="profile-rating-icons"
                            style={{
                              fill: "black",
                              height: "1.49vh",
                              width: ".722vw",
                            }}
                          />
                          <Text
                            className="lyrics-heading-2"
                            fontFamily={"Gilroy-SemiBold"}
                            fontSize=".729vw"
                            whiteSpace={"nowrap"}
                          >
                            {g.subGenre ? g.subGenre : g.genre}
                          </Text>
                        </Box>
                      </>
                    ))}
                  </Box>

                  {/* audio player */}

                  {userData.workSample && (
                    <audio
                      src={userData.workSample}
                      controls
                      style={{
                        width: "100%",
                        color: "orange",
                        fill: "orange",
                        marginTop: "5%",
                      }}
                    />
                  )}
                  {/* <Box mt="3.70vh">
                  <audio
                    style={{
                      width: "100%",
                      color: "orange",
                      fill: "orange",
                    }}
                    src={user?.workSample}
                    controls
                  />
                </Box> */}

                  {/* Description */}
                  <Text
                    mt="3.70vh"
                    fontFamily={"Gilroy-Medium"}
                    fontSize="1.2rem"
                  >
                    {userData.description}
                  </Text>

                  {/* Terms of Services */}
                  <Box mt="3.70vh">
                    {userData?.isMusician === "Musician" ? (
                      <>
                        <Text
                          className="lyrics-heading-1"
                          fontFamily={"Gilroy-Bold"}
                          fontSize="1.45vw"
                        >
                          Terms of Services
                        </Text>
                        <Text
                          as={"ul"}
                          className="lyrics-heading-2"
                          fontFamily={"Gilroy-Medium"}
                          fontSize=".833vw"
                          style={{
                            listStyle: "inside",
                          }}
                        >
                          {Array.isArray(userData.terms) &&
                            userData.terms.map((val, index) => {
                              return (
                                <Text
                                  as={"li"}
                                  key={index}
                                  paddingLeft={"10px"}
                                  fontFamily={"Gilroy-Medium"}
                                  fontSize="1.2rem"
                                >
                                  {val}
                                </Text>
                              );
                            })}
                          {/* {userData.terms} */}
                        </Text>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>

                  {/* Gear Highlights */}

                  {userData?.isMusician === "Musician" ? (
                    <Box mt="3.70vh">
                      <Text
                        className="lyrics-heading-1"
                        fontFamily={"Gilroy-Bold"}
                        fontSize="1.45vw"
                      >
                        Gear Highlights
                      </Text>
                      <Box
                        width={"fit-content"}
                        display={"grid"}
                        gridTemplateColumns="1fr 1fr"
                        columnGap={"2.40vh"}
                        rowGap={"10px"}
                        mt="2.40vh"
                      >
                        {/* icon */}
                        {userData.gearHighLights?.map((g, index) => (
                          <>
                            <Box
                              display={"flex"}
                              alignItems="center"
                              gap=".52vw"
                            >
                              {g.input1 === "Laptop" && (
                                <Monitor
                                  className="profile-rating-icons"
                                  style={{
                                    height: "1.25vw",
                                    width: "1.25vw",
                                  }}
                                />
                              )}
                              {g.input1 === "Microphone" && (
                                <Microphone
                                  className="profile-rating-icons"
                                  style={{
                                    height: "1.25vw",
                                    width: "1.25vw",
                                  }}
                                />
                              )}
                              {g.input1 === "Headphone" && (
                                <Headphone
                                  className="profile-rating-icons"
                                  style={{
                                    height: "1.25vw",
                                    width: "1.25vw",
                                  }}
                                />
                              )}

                              <Text
                                className="lyrics-heading-2"
                                fontFamily={"Gilroy-Medium"}
                                fontSize=".8333vw"
                              >
                                {g?.input1 ? g.input1 : g.gear}
                              </Text>
                            </Box>

                            {/* value */}
                            <Text
                              className="lyrics-heading-2"
                              fontFamily={"Gilroy-SemiBold"}
                              fontSize=".833vw"
                            >
                              {g?.input2 ? g.input2 : g.gearHighLights}
                            </Text>
                          </>
                        ))}
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>

              <Box className="m-hide" w="35vw" h="10px">
                {/* starting price section */}
                {userData?.startingPrice.length !== 0 && (
                  <Box
                    w="100%"
                    mt="2.22vh"
                    px="1.45vw"
                    pt="2.59vh"
                    pb="1.48vh"
                    border={"2px solid rgba(240, 240, 240, 1)"}
                    borderRadius="1.66vw"
                  >
                    <Box display={"flex"} justifyContent="space-between">
                      <Text fontFamily={"Gilroy-Bold"} fontSize="1.45vw">
                        Starting Price:
                      </Text>
                      <Text fontFamily={"Gilroy-Bold"} fontSize="1.45vw">
                        ₹{userData?.startingPrice}
                      </Text>
                    </Box>
                  </Box>
                )}

                <Box>
                  <Box
                    display={"grid"}
                    gridTemplateColumns="1fr 1fr"
                    columnGap={".833vw"}
                    rowGap="1.48vh"
                    mt="2.22vh"
                  >
                    {/* card-1  */}
                    {userData?.services?.map((item, index) => (
                      <Box
                        w="100%"
                        h="fit-content"
                        bg="rgba(192, 226, 24, .1)"
                        borderRadius={"1.66vw"}
                        px="1.34vw"
                        py={"2.22vh"}
                        display="flex"
                        flexDir={"column"}
                        key={index}
                        gap={"1rem"}
                      >
                        <Box display={"flex"} gap=".511vw">
                          <Category
                            style={{
                              height: "1.14vw",
                              width: "1.14vw",
                            }}
                          />
                          <Text
                            fontFamily={"Gilroy-SemiBold"}
                            fontSize="1.3rem"
                          >
                            {item.service !== undefined
                              ? item.service
                              : item.category}
                          </Text>
                        </Box>
                        <Text
                          marginLeft={"calc(1.14vw + 1rem)"}
                          fontFamily={"Gilroy-Bold"}
                          fontSize="1.6rem"
                        >
                          Starting Price: ₹{item.serviceStargingPrice}
                        </Text>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            {userData?.userData?.review ? (
              <Box mt="5.55vh">
                <Text
                  className="lyrics-heading-2"
                  fontFamily="Gilroy-Bold"
                  fontSize={"1.45vw"}
                >
                  {/* Reviews ({user.reviews?.length}) */}
                  Reviews (4)
                </Text>
                <Box
                  mt="1.01vh"
                  mb="7.40vh"
                  display={"flex"}
                  flexDir="column"
                  gap="1.48vh"
                >
                  <ReviewCard />;
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        )}

        <Footer />
      </Box>
    </>
  );
};

const Profile1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const username = useParams().username;

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const { userId } = AccessAuthContext();
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    setLoading(true);
  }, [user]);

  return <>{user ? "user" : "no-user"}</>;
};

export default Profile;
