export const createUserName = (str) => {
  if (!str) return '';
  
  // Remove all punctuation and special characters (including smart quotes and em dashes)
  str = str.replace(/[^\w\s-]/g, '');
  
  // Convert to lowercase
  str = str.toLowerCase();
  
  // Replace multiple spaces with single space and trim
  str = str.replace(/\s+/g, ' ').trim();
  
  // Replace spaces with hyphens
  str = str.replace(/\s/g, '-');
  
  // Remove consecutive hyphens
  str = str.replace(/-+/g, '-');
  
  return str;
};
