import React from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Set up PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFThumbnail({ pdfUrl }) {
  return (
    <div>
      <Document 
        file={pdfUrl}
        error={<div>Error loading PDF!</div>}
        loading={<div>Loading PDF...</div>}
      >
        <Page pageNumber={1} width={100} />
      </Document>
    </div>
  );
}

export default PDFThumbnail;
