import { Box, Image, Text } from "@chakra-ui/react";
import frame from "../../../../Assets/Images/icos/frame.png";
import clipboard from "../../../../Assets/Images/icos/clipboard-tick.png";
import coin from "../../../../Assets/Images/icos/coin.png";

const data = [
  {
    title: "Create Your Profile",
    description:
      "Sign up and create an Udukku Artist profile by adding your bio, description, genres, service rates, gear, and conditions.",
    image: frame,
  },
  {
    title: "Browse & Apply",
    description:
      "Apply to posted jobs by filtering your category, genre and pay, and begin working together with your client on mutually agreed terms",
    image: clipboard,
  },
  {
    title: "Get Paid Securely",
    description:
      "Complete the assigned project and receive your payment as soon as the work is approved and marked complete by the client",
    image: coin,
  },
];

const ShowCaseYourSkills = () => {
  return (
    <Box
      bg="rgba(8, 32, 50,.05)"
      pt="7.40vh"
      pb="7.40vh"
      //   h={{ lg: "43.03vh" }}
      display={"flex"}
      flexDir="column"
      justifyContent="start"
      alignItems={"center"}
    >
      <Text
        fontSize={{ base: "2rem !important", lg: "2.29vw !important" }}
        fontFamily={"Gilroy-Bold"}
        textAlign="center"
        className="hero-font-class1"
        paddingInline={{ base: "7vw" }}
      >
        Want To Showcase Your Skills?
      </Text>
      <Box
        display={"grid"}
        gridTemplateColumns={{ base: "repeat(1,1fr)", lg: "repeat(3,1fr)" }}
        justifyItems={"center"}
        px={{ base: "2rem", lg: "13.54vw" }}
        pt={{ base: "30px", lg: "50px" }}
        pb="10px"
        gridGap={{ base: "2rem", lg: "0rem" }}
      >
        {data.map((val, index) => {
          return (
            <Box
              key={index}
              display={"flex"}
              flexDir="column"
              alignItems={"center"}
              w={{ base: "70%", lg: "80%" }}
              gap="10px"
            >
              <Box
                w={{ base: "5.5rem", lg: "6vw" }}
                h={{ base: "5.5rem", lg: "6vw" }}
                border="3px solid #F6540E"
                borderRadius={"full"}
                display="flex"
                justifyContent={"center"}
                alignItems="center"
              >
                <Image
                  src={val.image}
                  h={{ base: "2.5rem", lg: "3vw" }}
                  w={{ base: "2.5rem", lg: "3vw" }}
                />
              </Box>
              <Text
                fontSize={{
                  base: "1.5rem !important",
                  lg: "1.45vw !important",
                }}
                fontFamily={"Gilroy-Bold"}
                className="hero-font-class1"
              >
                {val.title}
              </Text>
              <Text
                textAlign={"center"}
                fontFamily={"Gilroy-Medium"}
                fontSize={{
                  base: "1.2rem !important",
                  lg: "1.3rem !important",
                }}
                className="hero-font-class2"
              >
                {val.description}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ShowCaseYourSkills;
