import { Box, Avatar, AvatarBadge, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getChatroomsById } from "../../../Api/Chatroom/chatroom";

const MessageChatBox = ({ data }) => {
  const [chatrooms, setChatrooms] = useState([]);
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();


  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }

    getChatroomsById(user.userId)
      .then((res) => {
        setChatrooms(res.data);
      })
      .catch((err) => {
        console.error("Failed to fetch chatrooms", err);
      });
  }, []);

  return (
    <Box
      pl="2rem"
      pr="2rem"
      pt="1rem"
      pb="1rem"
      display={"flex"}
      flexDir={"row"}
      gap="1rem"
      _hover={{
        background: "#e5e5e5",
        cursor: "pointer",
      }}
      onClick={() => {
        for (let index = 0; index < chatrooms.length; index++) {
          if (
            chatrooms[index].userId.includes(
              data.jobId?.jobPostedBy?._id
            ) !== -1 &&
            chatrooms[index].jobId === data.jobId._id
          ) {
            navigate(`/contactMessage/${chatrooms[index]._id}`);
            document.location.reload(true);
          }
        }
      }}
    >
      {data ? (
        <Avatar size={"xl"} src={data.jobId?.jobPostedBy?.avatar}>
          {data.status === "active" ? (
            <AvatarBadge
              boxSize="0.6em"
              bg="#38C222"
              borderColor={"#38C222"}
              transform="translate(-23%, 9%)"
            />
          ) : (
            ""
          )}
        </Avatar>
      ) : (
        ""
      )}

      <Box display={"flex"} flexDir="column" p="7px" w="100%" gap="5px">
        <Box display={"flex"} flexDir={"row"} w="100%">
          <Text fontFamily={"Gilroy-Bold"} fontSize="1.3rem">
            {data.jobId?.jobTitle}
          </Text>
          <Text
            fontFamily={"Gilroy-SemiBold"}
            fontSize="1.3rem"
            color="#ACADAF"
            ml="auto"
          >
            30min
          </Text>
        </Box>
        <Text fontFamily={"Gilroy-SemiBold"} fontSize="1rem" color="#ACADAF">
          {data.description}
        </Text>
      </Box>
    </Box>
  );
};
export default MessageChatBox;
