import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { AccessAuthContext } from "../../Context/AuthContext";
import googleLogin from "../../../Api/Auth/googleLogin";
import BecomeOurMember from "../../Pages/Homepage/becomeOurMember/BecomeOurMember";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import SignInModalMobile from "./SignInModalMobile";
import SignUpModal from "./SignUpModal";

const PreSignIn = ({ state, changeState }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [signInState, setSignInState] = useState(false);
  const [signUpState, setSignUpState] = useState(false);
  const {
    setLoginState,
    setToken,
    setAvatar,
    setName,
    setUserId,
    setUserEmail,
  } = AccessAuthContext();

  // email and password
  const [email, setEmail] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);

  const [show_registration_modal, set_show_registration_modal] = useState(null);

  // login button state
  const [loginActive, setLoginActive] = useState(true);

  // for forgot password modal
  const [forgotPasswordModalState, setForgotPasswordModalState] =
    useState(false);


  const dispatch = useDispatch();

  // Handle Google login
  const handleGoogleLogin = async (response) => {
    try {
      const res = await googleLogin(response.credential);

      if (!res.data) {
        throw new Error('Login failed - no data received');
      }

      // Store user data
      localStorage.setItem("token", res.data.refresh_token);
      localStorage.setItem("userId", res.data.user._id);
      
      dispatch({
        type: "LOGGED_IN_USER",
        payload: {
          userId: res.data.user._id,
          name: res.data.user.name,
          email: res.data.user.email,
          token: res.data.user.refresh_token,
          isMusician: res.data.user.isMusician,
          isProfileCompleted: res.data.user.isProfileCompleted,
          qr: res.data.user.profileUrl,
          avatar: res.data.user.avatar,
        },
      });

      // Update auth context
      setLoginState(true);
      setToken(res.data.refresh_token);
      setUserId(res.data.user._id);
      setName(res.data.user.name);
      setUserEmail(res.data.user.email);
      setAvatar(res.data.user.avatar);

      onClose();
    } catch (error) {
      console.error('Google login error:', error);
      // Add your error handling/notification here
    }
  };

  useEffect(() => {
    if (state) {
      onOpen();
    }

    return () => {
      setCheckEmail(false);
      setCheckPassword(false);
    };
  }, [state]);

  useEffect(() => {
    if (email === "" || password === "") {
      setLoginActive(true);
    } else {
      setLoginActive(false);
    }
  }, [email, password]);


  return (
    <>
      <SignInModalMobile state={signInState} changeState={setSignInState} />
      <SignUpModal state={signUpState} changeState={setSignUpState} />
      {show_registration_modal === true ? (
        <BecomeOurMember state={true} />
      ) : (
        <></>
      )}
      <ForgotPasswordModal
        state={forgotPasswordModalState}
        changeState={setForgotPasswordModalState}
      />

      <Modal size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          height={"100vh"}
          bg="transparent"
          position={"relative"}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Box
            className="p-10"
            height={"100vh"}
            width={"100vw"}
            bg="#082032"
            py="3.70vh"
            px="3.125vw"
            position={"relative"}
            textAlign={"center"}
          >
            <Box display={"flex"} verticalAlign={"middle"}>
              <CloseIcon
                style={{
                  color: "#fff",
                  fontSize: "2.5rem",
                  marginRight: "8rem",
                }}
                position="absolute"
                top={"2.77vh"}
                right={"1.56vw"}
                cursor="pointer"
                onClick={() => {
                  changeState(false);
                  onClose();
                }}
              />
              <Text fontSize={"3rem"} fontWeight={"600"} color={"#fff"}>
                Udukku
              </Text>
            </Box>

            <Text
              fontSize={"4rem"}
              fontWeight={"600"}
              color={"#fff"}
              mt={"20vh"}
              fontFamily={"Gilroy-SemiBold"}
            >
              Explore <br /> Jobs
            </Text>
            <Box mt={"25vh"}>
              <Button
                bg="transparent"
                border="1px solid #F6540E"
                borderRadius={"6.04vw"}
                color={"#fff"}
                _hover={{ background: "rgba(215,85,28)" }}
                onClick={() => {
                  setSignUpState(true);
                }}
                fontFamily={"Gilroy-SemiBold"}
                fontSize={{ base: "1.5rem", lg: ".8333vw" }}
                w={"80vw"}
                h="6.66vh"
                id="navbar_become_member_btn"
              >
                Become a member
              </Button>

              <Text
                mt={"10%"}
                color={"#fff"}
                fontFamily={"Gilroy-SemiBold"}
                cursor={"pointer"}
                onClick={() => {
                  setSignInState(true);
                }}
                fontSize={{ base: "1.5rem", lg: ".8333vw" }}
              >
                Sign in
              </Text>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PreSignIn;
