import { Box, Button, Icon, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import JobCard from "../../../Components/jobCard/JobCard";
import { AiOutlineRight } from "react-icons/ai";
import getJobs from "../../../../Api/Jobs/getJobsApi";
import { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@splidejs/react-splide/css";

//jobTitle, service, genre, description, deadline, budget
const ExploreTheMarketplace = () => {
  const width = window.innerWidth;
  const [jobs, setJobs] = useState([
    {
      jobTitle: "",
      category: [{ service: "", subService: "" }],
      deadLine: "",
      budget: [],
      genres: [{ genere: "", subGenere: "" }],
      description: "",
    },
  ]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);
      const res = await getJobs();
      setJobs(Array.isArray(res.data) ? res.data : []);
      setLoading(false);
    } catch (error) {
      setJobs([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <Box
      bg="#fff"
      px={{ base: "7vw", lg: "13.54vw" }}
      // h="fit-content"
      py="50px"
      display={"flex"}
      flexDir="column"
      gap="3rem"
    >
      <Box
        display={"flex"}
        flexDir={{ base: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "flex-start", lg: "center" }}
        gap={{ base: "1rem" }}
      >
        <Box w={{ base: "100%", lg: "90%" }}>
          <Text
            className="hero-font-class1"
            fontSize={{ base: "2rem !important", lg: "2.29vw !important" }}
            fontFamily={"Gilroy-Bold"}
            // display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text textAlign={"start"}>Explore The Marketplace For A Job</Text>
            <Text
              fontSize={{ base: "1.2rem", lg: "1.04vw" }}
              fontFamily={"Gilroy-Medium"}
              textAlign={"start"}
            >
              Are you a musician looking to provide your skills? Browse here for
              your next opportunity.
            </Text>
          </Text>
          <Button
            display={{ base: "block", lg: "none" }}
            position="relative"
            fontFamily={"Gilroy-SemiBold"}
            fontWeight="normal"
            fontSize={{ base: "1.2rem", lg: ".833vw" }}
            borderRadius={{ base: "1.5rem", lg: "1.04vw" }}
            w={{ base: "200px", lg: "12.55vw" }}
            h={{ base: "4.7rem", lg: "6.66vh" }}
            onClick={() => {
              navigate("/jobs");
            }}
            marginTop={{ base: "1rem" }}
          >
            Explore more
            <Icon
              as={AiOutlineRight}
              ml="5px"
              fontSize={{ base: "9px", lg: "11px" }}
            />
          </Button>
        </Box>
        <Box>
          <Button
            display={{ base: "none", lg: "block" }}
            position="relative"
            fontFamily={"Gilroy-SemiBold"}
            fontWeight="normal"
            fontSize={{ base: "1.2rem", lg: ".833vw" }}
            borderRadius={{ base: "2rem", lg: "1.04vw" }}
            w={{ base: "15rem", lg: "12.55vw" }}
            h={{ base: "4.7rem", lg: "6.66vh" }}
            onClick={() => {
              navigate("/jobs");
            }}
          >
            Find your next project{" "}
            <Icon
              as={AiOutlineRight}
              ml="5px"
              fontSize={{ base: "9px", lg: "11px" }}
            />
          </Button>
        </Box>
      </Box>

      <Box
        display={"flex"}
        gap="20px"
        // mt="30px"
        // className="talents hide-it"
        // flexWrap={"nowrap"}
        // overflowX="scroll"
        id="talent_container"
      >
        <Splide
          onScroll={(item) => {}}
          options={{
            arrows: true,
            perPage: width < 500 ? 1 : 3,
            rewind: false,
            width: "100%",
            gap: width < 500 ? "3rem" : "1rem",
            perMove: 1,
            type: "loop",
          }}
        >
          {Array.isArray(jobs) && jobs.map((job, index) => {
            if (index >= 3) {
              return null;
            }
            const truncatedDescription = job.description?.length > 200 
              ? `${job.description.substring(0, 200)}...` 
              : job.description;
            
            return (
              <SplideSlide key={`job-slide-${job._id || index}`}>
                <JobCard
                  id={job._id}
                  title={job.jobTitle}
                  description={truncatedDescription}
                  category={job.category}
                  genres={job.genres}
                  deadline={job.deadLine}
                  budget={job.budget}
                  //subService={job.category[0].subService}
                  //subGenre={job.genres[0].subGenere}
                />
              </SplideSlide>
            );
          })}
        </Splide>
      </Box>
    </Box>
  );
};
export default ExploreTheMarketplace;
