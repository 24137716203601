import {
  Box,
  Avatar,
  AvatarBadge,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalHeader,
  Input,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as InfoIcon } from "../../../Assets/Icons/info-circle.svg";
import IncomingMessage from "./IncomingMessage";
import OutgoingTextMessage from "./OutgoingTextMessage";
import TypeMessageBox from "./TypeMessageBox";
import MessagingPageDetails from "./MessagingPageDetails";
import { useEffect, useState } from "react";
import MediaMessageDetail from "./MediaMessageDetails";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  deleteAttachment,
  getChatroomAttachmentsById,
  getChatroomById,
  getChatroomsById,
  sendMessage,
  updateChatroomById,
} from "../../../Api/Chatroom/chatroom";
import getJobById from "../../../Api/Jobs/getJobById";
import axios from "axios";
import { updateResponseById } from "../../../Api/Responses";
import { Textarea } from "@chakra-ui/react";
import ReactAudioPlayer from "react-audio-player";
import PDFThumbnail from "../../Utility/pdfThumbnail";
import PropTypes from 'prop-types';

const IndividualMessageBox = ({ socket, id }) => {
  const [messages, setMessages] = useState([]);
  const [chatroom, setChatroom] = useState();
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [getAttachments, setGetAttachments] = useState([]);
  const [media, setMedia] = useState([]);
  const [file, setFile] = useState("");
  const [response, setResponse] = useState({});
  const [deliverables, setDeliverables] = useState([]);
  const [documentation, setDocumentation] = useState();
  const [proposal, setProposal] = useState("");
  const [delievery, setDelievery] = useState("");
  const [cost, setCost] = useState("");
  const [infoToggle, setInfoToggle] = useState(false);
  const [goToMedia, setGoToMedia] = useState(true);
  const [job, setJob] = useState(null);

  const {
    isOpen: isProposalOpen,
    onOpen: onProposalOpen,
    onClose: onProposalClose,
  } = useDisclosure();

  const {
    isOpen: isDeliverablesOpen,
    onOpen: onDeliverablesOpen,
    onClose: onDeliverablesClose,
  } = useDisclosure();

  const toast = useToast();
  const navigate = useNavigate();
  const { user } = useSelector((state) => ({ ...state }));
  const sizes = ["xl"];

  const handleSizeClick = (newSize) => {
    onProposalOpen();
  };

  const handleSizeClick1 = (newSize1) => {
    onDeliverablesOpen();
  };

  const sendAttachment = async (e) => {
    const file = e.target.files[0];
    const MAX_FILE_SIZE = 1024 * 1024; // 1MB

    if (file.size > MAX_FILE_SIZE) {
      toast({
        title: "Error",
        description: "File size should be less than 1MB",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/upload_attachment`,
        formData
      );
      
      setFile(prev => [...prev, file.name]);
      setAttachments(prev => [...prev, res.data.url]);
      
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to upload file. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  useEffect(() => {
    let mounted = true;

    const fetchChatroom = async () => {
      try {
        const res = await getChatroomById(id);
        if (!mounted) return;
        
        setProposal(res.data?.proposalDetails);
        setDocumentation(res.data?.documentation);
        setDelievery(res.data?.deliveryDate);
        setCost(res.data?.cost);
        setChatroom(res.data);

        const jobRes = await getJobById(res.data.jobId);
        if (!mounted) return;
        setJob(jobRes.data);
        setResponse(
          jobRes.data.filter((item) => item.responseBy === user.userId)[0]
        );
      } catch (err) {
        console.error("Error fetching chatroom:", err);
      }
    };

    fetchChatroom();
    return () => { mounted = false; };
  }, [id, user.userId]);

  useEffect(() => {
    getChatroomAttachmentsById(id).then((res) => {
      setGetAttachments(res.data);
      setMedia(res.data);
    });
  }, [id]);

  const handleClick = async () => {
    if (!message.trim() && attachments.length === 0) return;

    try {
      await sendMessage(
        id, 
        message,
        user.userId,
        localStorage.getItem("token")
      );
      setMessage("");
    } catch (err) {
      console.error("Error sending message:", err);
      toast({
        title: "Error",
        description: "Failed to send message. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // to display or hide the message details box
  function infoHandler() {
    setInfoToggle(!infoToggle);
  }

  const handleAcceptJob = () => {
    const reqBody = {
      id,
      jobAccepted: "accepted",
    };
    updateChatroomById(reqBody)
      .then((res) => {
        getChatroomsById(id)
          .then((res) => {
            document.location.reload(true);
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };

  const handleMarkJobAsCompleted = () => {
    updateResponseById(job?._id, "completed", user.userId).then((res) => {
      navigate("/messages");
    });
  };

  const handleDenyJob = () => {
    updateResponseById(job?._id, "completed", user.userId).then((res) => {
      navigate("/messages");
    });
  };

  const handleSendDeliverables = () => {
    onProposalOpen(true);
    if (deliverables?.length === 0) {
      console.warn("please attach atleast one attachment");
    } else {
      const reqBody = {
        id,
        documentation,
        deliverables,
        deliverablesStatus: true,
      };
      updateChatroomById(reqBody)
        .then((res) => { })
        .catch((err) => { })
        .then(() => {
          onDeliverablesClose();
        });
    }
  };

  const handleSubmit = () => {
    if (delievery === "" || cost === "") {
      alert("please fill all the fields");
    } else {
      const reqBody = {
        id,
        status: "accepted",
        deliverables,
        documentation,
        jobAccepted: "accepted",
        deliveryDate: delievery,
        proposalDetails: proposal,
        // proposal,
        cost: parseInt(cost),
      };
      updateChatroomById(reqBody)
        .then((res) => {
          document.location.reload(true);
        })
        .catch((err) => { });
    }
    // window.$("#exampleModal1").modal("hide");
  };


  const handleDeliverables = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const fileName = e.target.files[0].name;
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/upload_attachment`, formData)
      .then((res) => {
        setFile((oldArr) => [...oldArr, fileName]);
        setDeliverables((oldArr) => [...oldArr, res.data]);
        alert("your document has has been uploaded");
      })
      .catch((err) => { });
  };

  const handleRemove = (id, index) => {
    let resourceType = "";
    if (
      deliverables[index].secure_url.search("png") ||
      deliverables[index].secure_url.includes("jpg") ||
      deliverables[index].secure_url.includes("jpeg")
    ) {
      resourceType = "image";
    } else if (
      deliverables[index].secure_url.includes("mp3") ||
      deliverables[index].secure_url.includes("mp4") ||
      deliverables[index].secure_url.includes("aac") ||
      deliverables[index].secure_url.includes("wav")
    ) {
      resourceType = "video";
    } else {
      resourceType = "raw";
    }

    const filter = deliverables.filter((item) => {
      return item.public_id !== id;
    });
    deleteAttachment(id, resourceType)
      .then((res) => { })
      .catch((err) => { });
    setDeliverables(filter);
    };

  const size = "xl";

  return (
    // contains both i button box and message box
    <Box display={"flex"} flexDir="row" w="65%" className="w100">
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
        w={infoToggle ? "60%" : "100%"}
      >
        <Box
          p="2rem"
          display={"flex"}
          flexDir="row"
          gap="1rem"
          borderBottom={"2px"}
          borderColor={"#F0F0F0"}
          alignItems="center"
        >
          <Avatar size={"lg"} src={job?.jobPostedBy?.avatar}>
            <AvatarBadge
              boxSize="0.6em"
              bg="#38C222"
              borderColor={"#38C222"}
              transform="translate(-23%, 9%)"
            />
          </Avatar>
          <Text
            fontFamily={"Gilroy-Bold"}
            fontSize="1.3rem"
            alignSelf={"center"}
          >
            {job?.jobPostedBy?.name}
          </Text>
          <Box
            display={"flex"}
            flexDir={"row"}
            gap="1rem"
            alignItems={"center"}
            ml="auto"
          >
            {response?.status === "exploring" &&
              chatroom.jobAccepted !== "accepted" ? (
              <>
                <Button
                  backgroundColor={"#F6540E"}
                  color={"White"}
                  pt={"1.75rem"}
                  pb={"1.75rem"}
                  borderRadius={"2rem"}
                  onClick={handleAcceptJob}
                  disabled={response?.status == "active"}
                >
                  Accept Job
                </Button>
                <Button
                  backgroundColor={"#F6540E"}
                  color={"White"}
                  pt={"1.75rem"}
                  pb={"1.75rem"}
                  borderRadius={"2rem"}
                  onClick={handleDenyJob}
                  disabled={response?.status == "active"}
                >
                  Deny Job
                </Button>
              </>
            ) : response?.status === "exploring" &&
              chatroom.jobAccepted === "accepted" &&
              !chatroom.proposalDetails ? (
              <>
                <Button
                  backgroundColor={"#F6540E"}
                  color={"White"}
                  pt={"1.75rem"}
                  pb={"1.75rem"}
                  borderRadius={"2rem"}
                  onClick={() => handleSizeClick(size)}
                  key={size}
                  disabled={
                    chatroom?.jobAccepted !== "accepted" ||
                    chatroom.proposalDetails
                  }
                >
                  Send Proposal
                </Button>

                <Button
                  backgroundColor={"#F6540E"}
                  color={"White"}
                  pt={"1.75rem"}
                  pb={"1.75rem"}
                  borderRadius={"2rem"}
                  onClick={handleDenyJob}
                >
                  Deny Job
                </Button>
              </>
            ) : response?.status === "exploring" &&
              chatroom?.jobAccepted === "accepted" &&
              chatroom?.paymentStatus === true &&
              !chatroom?.deliverables ? (
              <>
                <Button
                  backgroundColor={"#F6540E"}
                  color={"White"}
                  pt={"1rem"}
                  pb={"1rem"}
                  mx={"auto"}
                  borderRadius={"2rem"}
                  onClick={handleSizeClick1}
                  disabled={
                    chatroom?.paymentStatus == false || chatroom?.deliverables
                  }
                >
                  Send Deliverables
                </Button>
                <Modal
                  style={{ width: "80vw" }}
                  isOpen={isDeliverablesOpen}
                  size={sizes}
                  onClose={onDeliverablesClose}
                >
                  <ModalOverlay />
                  <ModalContent mt="auto" mb="auto">
                    <ModalHeader>Deliverables</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <label
                        for="document"
                        className="btn btn-outline-primary w-100"
                      >
                        Choose files
                      </label>
                      <input
                        multiple
                        className="form-control"
                        style={{ display: "none" }}
                        type="file"
                        name="document"
                        id="document"
                        onChange={handleDeliverables}
                      />

                      {chatroom !== undefined
                        ? deliverables.map((attach, index) =>
                          attach.secure_url.search("png") !== -1 ||
                            attach.secure_url.search("jpg") !== -1 ||
                            attach.secure_url.search("jpeg") !== -1 ? (
                            <AvatarBadge
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleRemove(attach.public_id, index)
                              }
                            >
                              <Avatar
                                shape="square"
                                className="mb-3"
                                src={attach.secure_url}
                                size={60}
                                style={{ marginLeft: "1rem" }}
                              />
                            </AvatarBadge>
                          ) : attach.secure_url.search("mp3") !== -1 ||
                            attach.secure_url.search("mp4") !== -1 ||
                            attach.secure_url.search("wav") !== -1 ||
                            attach.secure_url.search("aac") !== -1 ? (
                            <ReactAudioPlayer
                              key={index}
                              src={attach.secure_url}
                              controls
                              controlsList="nodownload"
                              style={{ width: "90%", height: "25px" }}
                            />
                          ) : (
                            <p>{attach.secure_url}</p>
                          )
                        )
                        : ""}
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        backgroundColor={"#F6540E"}
                        color={"White"}
                        pt={"1rem"}
                        pb={"1rem"}
                        mx={"auto"}
                        borderRadius={"2rem"}
                        onClick={handleSendDeliverables}
                      >
                        Send Deliverables
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </>
            ) : response?.status === "exploring" &&
              chatroom?.jobAccepted === "accepted" &&
              chatroom?.paymentStatus === true &&
              chatroom?.deliverables ? (
              <Button
                backgroundColor={"#F6540E"}
                color={"White"}
                pt={"1.75rem"}
                pb={"1.75rem"}
                borderRadius={"2rem"}
                onClick={handleMarkJobAsCompleted}
                disabled={response?.status == "completed"}
              >
                Mark job as Completed
              </Button>
            ) : response?.status == "completed" &&
              chatroom?.jobAccepted === "accepted" &&
              chatroom?.paymentStatus === true &&
              chatroom?.deliverables ? (
              <Button
                backgroundColor={"#F6540E"}
                color={"White"}
                pt={"1.75rem"}
                pb={"1.75rem"}
                borderRadius={"2rem"}
                disabled
              >
                Job is Completed
              </Button>
            ) : (
              <>
                {chatroom?.proposalDetails &&
                  chatroom?.jobAccepted === "accepted" ? (
                  <Button
                    backgroundColor={"#F6540E"}
                    color={"White"}
                    pt={"1.75rem"}
                    pb={"1.75rem"}
                    borderRadius={"2rem"}
                    onClick={() => handleSizeClick(size)}
                    disabled={response?.status === "active"}
                  >
                    Edit Proposal
                  </Button>
                ) : (
                  <>
                    <Button
                      backgroundColor={"#F6540E"}
                      color={"White"}
                      pt={"1.75rem"}
                      pb={"1.75rem"}
                      borderRadius={"2rem"}
                      onClick={handleAcceptJob}
                      disabled={response?.status === "active"}
                    >
                      Accept Job
                    </Button>
                    <Button
                      backgroundColor={"#F6540E"}
                      color={"White"}
                      pt={"1.75rem"}
                      pb={"1.75rem"}
                      borderRadius={"2rem"}
                      onClick={handleDenyJob}
                      disabled={response?.status === "active"}
                    >
                      Deny Job
                    </Button>
                  </>
                )}
              </>
            )}
            {/* on click , should show the message details box */}
            <InfoIcon
              style={{ fontSize: "5px", cursor: "pointer" }}
              onClick={() => infoHandler()}
            />
          </Box>
        </Box>
        <Box
          w="100%"
          p="2rem"
          display={"flex"}
          flexDir="column"
          gap="1rem"
          alignItems={"flex-start"}
          overflow={"scroll"}
          scrollBehavior="smooth"
          css={{
            "::-webkit-scrollbar": {
              width: "5px",
            },

            "::-webkit-scrollbar-thumb": {
              background: "#888",
            },
          }}
        >
          {user
            ? messages.map((item) => {
              if (item.user !== user.userId) {
                return (
                  <Box display={"flex"} flexDir="row" gap="1rem">
                    <Avatar size={"lg"} src={item.avatar}></Avatar>
                    <Box
                      display={"flex"}
                      flexDir="column"
                      gap="1rem"
                      w="auto"
                    >
                      <Box
                        display={"flex"}
                        flexDir="row"
                        gap="1rem"
                        alignItems={"center"}
                      >
                        <IncomingMessage data={item} />
                        <Text
                          fontFamily={"Gilroy-SemiBold"}
                          fontSize="1rem"
                          color="#ACADAF"
                          ml="auto"
                        >
                          30min
                        </Text>
                      </Box>
                      {/* <IncomingImageMessage /> */}
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Box
                    ml="auto"
                    display={"flex"}
                    flexDir="column"
                    gap="1rem"
                    alignItems={"flex-end"}
                  >
                    <OutgoingTextMessage data={item} />
                  </Box>
                );
              }
            })
            : ""}
        </Box>
        <Box p="1rem" pos={"sticky"} top={"80rem"}>
          <TypeMessageBox
            sendAttachment={sendAttachment}
            sendMessage={handleClick}
            message={message}
            setMessage={setMessage}
          />
          <Box
            marginTop={"10px"}
            display={"flex"}
            flexWrap={"wrap"}
            gap={"10px"}
          >
            {attachments.map((item) => {
              let type = item.split(".").splice(item.split(".").length - 1)[0];

              return (
                <>
                  {(type === "jpg" ||
                    type === "jpeg" ||
                    type === "png" ||
                    type === "webp") && (
                      <img
                        src={item}
                        style={{
                          borderRadius: "5px",
                          height: "100px",
                          width: "auto",
                          flexShrink: 0,
                        }}
                        alt=""
                      />
                    )}

                  {type === "pdf" && <PDFThumbnail pdfUrl={item} />}

                  {type === "mp4" && (
                    <Box>
                      {/* don't play video */}
                      <video style={{ height: "100px", width: "100px" }}>
                        <source src={item} type="video/mp4" />
                      </video>
                    </Box>
                  )}
                </>
              );
            })}
          </Box>
        </Box>
      </Box>
      {/* if go to media is true-> display message detail box
      else -> display media box */}
      {goToMedia ? (
        <MessagingPageDetails
          state={infoToggle}
          setInfoToggle={setInfoToggle}
          goToMedia={goToMedia}
          setGoToMedia={setGoToMedia}
          data={job}
          media={media}
          getAttachments={getAttachments}
          chatroom={chatroom}
        />
      ) : (
        <MediaMessageDetail
          getAttachments={getAttachments}
          data={media}
          goToMedia={goToMedia}
          setGoToMedia={setGoToMedia}
        />
      )}

      <Modal
        style={{ width: "80vw" }}
        isOpen={isProposalOpen}
        size={sizes}
        onClose={onProposalClose}
      >
        <ModalOverlay />
        <ModalContent mt="auto" mb="auto">
          <ModalHeader>Proposal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              className="proposal-modal-input"
              onChange={(e) => setProposal(e.target.value)}
              value={proposal}
              placeholder="Deliverables"
            />
            <Textarea
              className="proposal-modal-input"
              onChange={(e) => setDocumentation(e.target.value)}
              placeholder="Terms  of Services"
              value={documentation}
            />
            <label className="mt-3">Delivery Date</label>
            <Input
              placeholder="Select Date and Time"
              size="md"
              type="datetime-local"
              onChange={(e) => setDelievery(e.target.value)}
              value={delievery}
            />

            <Input
              className="proposal-modal-input"
              variant="outline"
              placeholder="Final Proposal Cost"
              onChange={(e) => setCost(e.target.value)}
              value={cost}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor={"#F6540E"}
              color={"White"}
              pt={"1rem"}
              pb={"1rem"}
              mx={"auto"}
              borderRadius={"2rem"}
              onClick={handleSubmit}
            >
              Send Proposal
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

IndividualMessageBox.propTypes = {
  socket: PropTypes.object,
  id: PropTypes.string.isRequired
};

export default IndividualMessageBox;
